/*
 * @Description:
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-10 00:57:27
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-31 16:55:35
 */
import { Suspense, useState, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import Loading from "@/components/loading";
import { routes } from "@/router";
import session from "@/utils/session";
import "antd/dist/antd.css";
import "./global.scss";
import NotFound from "@/pages/notFound";
import Favicon from "react-favicon";
import { websiteCurrent } from "@/services/request";

export default withRouter(function App(props: any) {
  let routeLists: any = routes;
  let [websiteInfo, setWebsiteInfo] = useState<any>({});
  const getWebsiteData = () => {
    websiteCurrent().then((res: any) => {
      let data = (res.data && res.data.cityName ? res.data : undefined) || {
        cityName: process.env.REACT_APP_CITY_NAME,
        siteLogo: process.env.REACT_APP_SITELOGO,
        favicon: process.env.REACT_APP_FAVICON,
      };
      if (data) {
        session.set("websiteData", data);
        setWebsiteInfo(data);
      }
    });
  };
  //渲染完成执行
  useEffect(() => {
    getWebsiteData();
  }, []);

  return (
    <>
      <Favicon url={websiteInfo.favicon} />
      {websiteInfo.cityName ? (
        <Router>
          <Suspense fallback={<Loading size="large" />}>
            <Switch>
              {routeLists.map((item: any, index: number) => {
                return item.component ? CreateRoute(props, item, index) : "";
              })}
              <Route path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      ) : (
        ""
      )}
    </>
  );
});

//创建路由
const CreateRoute = (props: any, item: any, index: number) => {
  let [tokenData, pathname] = [
    session.getLoca("token"),
    props.location.pathname,
  ];
  return pathname === "/login" && tokenData ? (
    <Redirect key="/home" to="/home" />
  ) : pathname !== "/login" && !tokenData ? (
    <Redirect key="/login" to="/login" />
  ) : item.path === pathname && item.redirect ? (
    <Redirect key={index} to={item.redirect} />
  ) : (
    <Route
      key={index}
      exact={item.exact}
      path={item.path}
      render={() => {
        return <item.component children={item.children} />;
      }}
    />
  );
};
