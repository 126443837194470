/*
 * @Description:api
 * @Author: tianyu
 * @Email: jackTian9527@163.com
 * @Date: 2021-02-01 10:01:46
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-31 10:28:51
 */
import request from '@/utils/request'
console.log(process.env)

function tableLoading(type: string) {
  return type === 'get' ? true : false
}
function buttonLoading(type: string) {
  //修改删除时触发update
  return type === 'post' || type === 'put' || type === 'delete' ? true : false
}

//获取站点信息
export function websiteCurrent() {
  return request({
    url: '/auth/site/current',
    method: 'get'
  })
}

//登录
export function login(data: any) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

//获取验证码
export function getCode() {
  return request({
    url: '/auth/VCode',
    method: 'get'
  })
}

//数据字典
export function lookupType(method: string, data?: any) {
  return request({
    url: '/comm/lookup/type',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//字典子级
export function lookupVal(method: string, data?: any) {
  return request({
    url: '/comm/lookup/value',
    method,
    data,
    tableChildLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//数据字典查询
export function lookupValActive(method: string, data?: any) {
  return request({
    url: '/comm/lookup/value/active',
    method,
    data
  })
}

//查询其他other类型
export function otherType(api: string, data?: any) {
  return request({
    url: api,
    method: 'get',
    data
  })
}

//账号
export function account(method: string, data?: any) {
  return request({
    url: '/auth/account',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//账号列表查询
export function accountPage(method: string, data?: any) {
  return request({
    url: '/auth/account/page',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//角色
export function role(method: string, data?: any) {
  return request({
    url: '/auth/role',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//角色添加权限
export function roleBindAuth(data?: any) {
  let method = 'put'
  return request({
    url: '/auth/role/element',
    method,
    data,
    tableLoading: true
  })
}

//查询角色下所有权限
export function roleAllAuth(data?: any) {
  let method = 'get'
  return request({
    url: '/auth/element/role',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//城市站
export function domain(method: string, data?: any) {
  return request({
    url: '/auth/domain',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//城市站列表
export function domainPage(method: string, data?: any) {
  return request({
    url: '/auth/domain/page',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}
//站点
export function site(method: string, data?: any) {
  let urlGetStr = method === 'get' ? '/list' : ''
  return request({
    url: '/auth/site' + urlGetStr,
    method,
    data,
    tableChildLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//站点角色
export function siteMember(method: string, data?: any) {
  return request({
    url: '/auth/site/member',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//权限
export function authority(method: string, data?: any) {
  return request({
    url: '/auth/authority',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//获取
export function getWapUploadUrl(data: any) {
  return request({
    url: '/comm/upload/getWapUploadUrl',
    method: 'get',
    data
  })
}

//查询角色成员
export function member(method: string, data: any) {
  return request({
    url: '/auth/role/member',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//上传图片
export function uploadImg(
  url: string,
  data?: any,
  headers: any = { 'Content-Type': 'application/octet-stream', 'x-oss-meta-author': 'aliy' },
  onUploadProgress?: any,
  timeout?: 83000
) {
  return request({
    url,
    method: 'put',
    data,
    headers,
    custom: {
      error: false,
      timeout
    }
  }, onUploadProgress)
}

//获取select
/**
 *
 * @param url
 * @param customData 自定义参数(便于扩展)
 */
export function select(url: string, customData: any) {
  return request({
    url,
    method: 'get',
    ...customData
  })
}

//获取页面元素
export function element(method: string, data?: any) {
  return request({
    url: '/auth/element',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//产品
export function productList(method: string, data?: any) {
  return request({
    url: '/buss/product',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}
//产品列表
export function productListPage(method: string, data?: any) {
  return request({
    url: '/buss/product/page',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}
//新增产品
export function product(method: string, data: any) {
  return request({
    url: '/buss/product',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//查询待办申请列表
export function todo(method: string, data: any) {
  return request({
    url: '/bpm/workspace/todo',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//查询已办列表
export function approved(method: string, data: any) {
  return request({
    url: '/bpm/workspace/approved',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//查询所有流程列表
export function allProcess(method: string, data: any) {
  return request({
    url: '/bpm/workspace/check',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//查询流程表单
export function processForm(method: string, data: any) {
  return request({
    url: '/bpm/workspace/detail',
    method,
    data
  })
}

//修改保存流程
export function processUpdate(method: string, data: any) {
  return request({
    url: '/buss/order',
    method,
    data
  })
}
//审批流程
export function processApprove(method: string, data: any) {
  return request({
    url: '/bpm/process/flow/approve',
    method,
    data,
    buttonLoading: buttonLoading(method)
  })
}

//查询审批历史
export function processHistory(method: string, data: any) {
  return request({
    url: '/bpm/workspace/history',
    method,
    data
  })
}

//查询放款统计
export function loanAmount(data: any = {}) {
  let method = 'get'
  return request({
    url: '/view/bank/kanban/loanAmount',
    method,
    data
  })
}

//新闻资讯
export function newInfoList(method: string, data: any) {
  return request({
    url: '/view/news',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//查询新闻资讯
export function newInfoListPage(method: string, data: any) {
  return request({
    url: '/view/news/page',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//新闻资讯are
export function newInfo(method: string, data: any) {
  return request({
    url: '/view/news',
    method,
    data,
    buttonLoading: buttonLoading(method)
  })
}

//查询页面元素
export function pageElement(method: string, data?: any) {
  return request({
    url: '/auth/element',
    method,
    data
  })
}

//礼券管理
export function voucher(method: string, data?: any) {
  return request({
    url: '/buss/voucher',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}
//礼券管理查询
export function voucherPage(method: string, data?: any) {
  return request({
    url: '/buss/voucher/page',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}
//礼券领取记录
export function voucherAccount(method: string, data?: any) {
  return request({
    url: '/buss/voucher/voucherAccount',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}
//查询礼券领取记录
export function voucherAccountPage(method: string, data?: any) {
  return request({
    url: '/buss/voucher/voucherAccount/page',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//经纪人管理
export function agent(method: string, data?: any) {
  return request({
    url: '/buss/agent',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}
//经纪人列表查询
export function agentPage(method: string, data?: any) {
  return request({
    url: '/buss/agent/page',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//查询客户
export function client(method: string, data?: any) {
  return request({
    url: '/buss/order/client',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//查询客户列表
export function clientPage(method: string, data?: any) {
  return request({
    url: '/buss/order/client/page',
    method,
    data,
    tableLoading: tableLoading(method),
    buttonLoading: buttonLoading(method)
  })
}

//大文本列表配置
export function longTextList(method: string, data?: any) {
  return request({
    url: '/comm/longtext',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}
//查询大文本列表配置
export function longTextListPage(method: string, data?: any) {
  return request({
    url: '/comm/longtext/page',
    method,
    data,
    tableLoading: tableLoading(method)
  })
}

//大文本
export function longtext(method: string, data?: any) {
  return request({
    url: '/comm/longtext',
    method,
    data,
    buttonLoading: buttonLoading(method)
  })
}


// 金融课堂 视频列表

export function videos(method: string, data?: any) {
  return request({
    url: '/view/video/page',
    method,
    data
  })
}

export function videoDelete(data:any) {
  return request({
    url: '/view/video',
    method: 'delete',
    data
  })
}

export function videoReq(method: string,data:any) {
  return request({
    url: '/view/video',
    method,
    data
  })
}


// 机构
export function orgReq(method: string, data:any) {
  return request({
    url: '/view/mechanism',
    method,
    data
  })
}

// 机构列表
export function orgs(data:any) {
  return request({
    url: '/view/mechanism/page',
    method: 'get',
    data
  })
}

// 案例围观查询
export function caseQuery(method:string,data:any) {
  return request({
    url: '/buss/case/pageQuery',
    method,
    data
  })
}

// 案例围观新增
export function addCase(method:string,data:any) {
  return request({
    url: '/buss/case/add',
    method,
    data
  })
}

// 案例围观删除
export function caseDelete(method:string,data:any) {
  return request({
    url: '/buss/case/delete',
    method,
    data
  })
}

// 案例围观详情
export function caseDetail(method:string,data:any) {
  return request({
    url: '/buss/case/detail',
    method,
    data
  })
}

// 案例围观修改
export function caseModify(method:string,data:any) {
  return request({
    url: '/buss/case/modify',
    method,
    data
  })
}

// 查询总数据
export function archivesTotal(method:string) {
  return request({
    url: '/government/queryArchiveLoanAmount',
    method
  })
}

// 查询列表
export function archivesList(method:string,data:any) {
  return request({
    url: '/government/pageQueryArchives',
    method,
    data
  })
}

// 查询详情
export function archivesDetail(method:string,data:any) {
  return request({
    url: '/government/queryArchiveDetails',
    method,
    data
  })
}